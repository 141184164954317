import { graphql } from "gatsby";
import { get } from "lodash";
import React from "react";
import tw from "twin.macro";
import CtaBlock from "../components/CtaBlock";
import HeaderCarousel from "../components/HeaderCarousel";
import HowItWorks from "../components/HowItWorks";
import SEO from "../components/Seo";
import SliceZone from "../components/SliceZone";
import Layout from "../layouts/layout";

const Wrapper = tw.section`
  w-full bg-ink pb-12 pt-8 px-12 md:px-8 lg:px-0
`
const IframeWrapper = tw.section`
  relative max-w-screen-xl mx-auto px-4 lg:max-w-screen-lg
  md:flex md:flex-row md:items-start z-20 bg-ink rounded-xl
  overflow-hidden bg-white shadow-md
`;

const TextWrapper = tw.div`
  relative max-w-screen-xl mx-auto lg:max-w-screen-lg
  md:flex md:flex-row md:items-start overflow-hidden
  text-gray-100 text-3xl font-semibold mb-4
`

const Home = ({ data }) => {
  const page = get(data, "prismic.allHomepages.edges[0].node");
  return (
    <Layout>
      <SEO title="Your On-Demand Personal Chauffeur and Assistant" />

      <HeaderCarousel carousel={page.carousel} />

      <HowItWorks steps={page.steps} intro={page.intro} />

      <Wrapper>

        <TextWrapper>Got Questions? Let Our AI Assistant Help You</TextWrapper>
        <IframeWrapper>
          <iframe
            src="https://www.chatbase.co/chatbot-iframe/U_yo15E-IQQ_ahA-9HDwv"
            width="100%"
            style={{height: '100%', minHeight: '500px'}}
            frameborder="0"
            />
        </IframeWrapper>
      </Wrapper>

      <SliceZone sliceZone={page.body} trackPage="homepage" />
      <CtaBlock cta="Contact Us" ctalink="mailto:support@jeevz.com">
        Questions? Talk to our concierge team today.
      </CtaBlock>
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query HomepageQuery {
    prismic {
      allHomepages {
        edges {
          node {
            _meta {
              uid
            }
            carousel {
              content
              title
              background_image
              carousel_content {
                ... on PRISMIC_Landing_page {
                  page_title
                  body {
                    ... on PRISMIC_Landing_pageBodyHeader_block {
                      primary {
                        intro__copy
                        page_header
                        header_background
                      }
                      label
                      type
                    }
                  }
                  _meta {
                    uid
                    type
                  }
                }
                ... on PRISMIC_Profession {
                  page_title
                  body {
                    ... on PRISMIC_ProfessionBodyHeader_block {
                      type
                      label
                      primary {
                        header_background
                        intro__copy
                        page_header
                      }
                    }
                  }
                  _meta {
                    uid
                    type
                  }
                }
                ... on PRISMIC_City {
                  page_title
                  live
                  body {
                    ... on PRISMIC_CityBodyHeader_block {
                      type
                      label
                      primary {
                        header_background
                        intro__copy
                        page_header
                      }
                    }
                  }
                  _meta {
                    uid
                    type
                  }
                }
                ... on PRISMIC_Use_case {
                  page_title
                  live
                  body {
                    ... on PRISMIC_Use_caseBodyHeader_block {
                      type
                      label
                      primary {
                        header_background
                        intro__copy
                        page_header
                      }
                    }
                  }
                  _meta {
                    uid
                    type
                  }
                }
              }
            }
            intro
            steps {
              step_callout
              step_header
              step_number
              step_text
            }
            body {
              ... on PRISMIC_HomepageBodyLifestyle_image {
                type
                label
                primary {
                  headline
                  jumbo_image
                  jumbo_imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 1920, quality: 85) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              ... on PRISMIC_HomepageBodyFeature_picker__grid {
                type
                label
                primary {
                  headline
                }
                fields {
                  feature {
                    ... on PRISMIC_Feature_tile {
                      feature_icon
                      feature_title
                      feature_description
                    }
                  }
                }
              }
              ... on PRISMIC_HomepageBodyPricing_module {
                type
                label
                primary {
                  show_pricing_module
                }
              }
              ... on PRISMIC_HomepageBodyCovid {
                type
                label
                primary {
                  show_this_block
                }
              }
              ... on PRISMIC_HomepageBodyTestimonials {
                type
                label
                fields {
                  testimonials {
                    ... on PRISMIC_Testimonial {
                      quote
                      author
                      bio
                      photo
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
