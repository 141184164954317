import React, { useState } from "react";
import tw, { styled } from "twin.macro";
import { useSwipeable } from "react-swipeable";
import { RichText } from "prismic-reactjs";
import Navigation from "./Navigation";
import { toPath } from "../utils/paths";
import Pattern from "../img/bg-pattern.png";
import { H1, P, clipPath } from "../utils/helpers";
import AppStoreLinks from "./AppStoreLinks";
import TextApp from "./TextApp";
import Link from "./BaseLink";

const HeaderWrapper = styled.div`
  ${tw`relative bg-gray-500 overflow-hidden`}
`;

const CarouselWrapper = styled.div`
  ${tw`relative w-screen h-full flex flex-row z-20 transform transition duration-500 ease-in-out`}
  transform: translateX(-${(props) => props.activeSlide * 100}%);
`;

const Container = styled.div`
  ${tw`relative w-screen pb-40 pt-24 md:pt-36 md:pb-80 flex-none z-30 justify-center transition-all duration-200 ease-in-out`}
`;

const BGOverlay = styled.div`
  ${tw`absolute w-full h-full inset-0 z-20 bg-black opacity-75`}
  background-image: linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(34,39,97,0.7) 100%), url("${Pattern}");
  background-repeat: repeat;
`;

const BGImage = styled.img`
  ${tw`absolute w-full h-full inset-0 object-cover transform transition duration-500 ease-in-out z-20`}
  ${(props) => (props.isActive ? tw`opacity-100` : tw`opacity-0`)}
`;

const HomeHeader = styled.div`
  ${tw`relative text-center w-10/12 p-2 mx-auto md:w-3/4 lg:max-w-3xl z-30 transition-all duration-500 ease-in-out`}
`;

const Content = styled.div`
  ${tw`relative text-5xl md:mx-0 md:text-6xl md:mt-10 px-4 md:px-0 pb-8 z-40 text-white transition-all duration-200 ease-in-out`}
  ${(props) =>
    props.isActive ? tw`h-auto opacity-100` : tw`h-0 opacity-0`}
  & H1 {
    ${tw`leading-none font-thin`}
  }
  @media (min-width: 768px) {
    & H1 {
      font-size: calc(3.5rem + 1vh);
    }
  }
  & P {
    ${tw`md:text-xl`}
  }
`;

const LearnMore = tw(Link)`
  relative block uppercase font-medium tracking-wider underline text-base p-4 pb-8 hover:text-fire transition-all duration-300 ease-in-out -mt-2
`;

const TextForm = tw.div`
  hidden md:block relative md:z-20 md:-mt-80 md:mb-64 transition-all duration-300 ease-in-out
`;

const AppLinks = tw.div`
  relative -mt-56 right-4 w-7/12 -mr-6 ml-auto mb-6 z-20
`;

const CarouselArrows = tw.div`
  absolute h-20 top-1/2 w-screen max-w-7xl mx-auto transform -translate-x-1/2 left-1/2 z-50
`;

const NextButton = styled.button`
  clip-path: ${clipPath.sm};
  ${tw`bg-fire hover:bg-white text-white hover:text-ink absolute w-8 h-8 md:w-12 md:h-12 inline-flex items-center text-center text-lg z-30 cursor-pointer right-2 md:right-10 top-1/2 px-2 md:px-4 transition duration-300 ease-in-out -mt-3/12 md:-mt-32 lg:-mt-20`}
`;

const PrevButton = styled(NextButton)`
  ${tw`left-2 md:left-10`}
  transform: scaleX(-1);
`;

const Header = ({ carousel }) => {
  const [active, setActive] = useState(0);

  const nextSlide = () => {
    active === carousel.length - 1
      ? setActive((active) => 0)
      : setActive((active) => active + 1);
    console.log("NEXT", active);
  };

  const prevSlide = () => {
    active === 0
      ? setActive((active) => carousel.length - 1)
      : setActive((active) => active - 1);
    console.log("PREV", active);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide,
    onSwipedRight: () => prevSlide,
    preventDefaultTouchmoveEvent: true,
    // trackMouse: true,
  });

  // setInterval(nextSlide, 10000);

  return (
    <HeaderWrapper>
      <Navigation />
      <CarouselWrapper
        activeSlide={active}
        numSlides={carousel.length}
        {...handlers}
      >
        {carousel.map(
          ({ carousel_content, background_image, title, content }, idx) => (
            <Container key={`carousel-header-${idx}`}>
              <BGImage
                isActive={Math.abs(active) % carousel.length === idx}
                src={
                  carousel_content === null
                    ? background_image.url
                    : carousel_content.body[0].primary.header_background.url
                }
                alt=""
              />

              <HomeHeader isActive={Math.abs(active) % carousel.length === idx}>
                <Content isActive={Math.abs(active) % carousel.length === idx}>
                  <H1>
                    {carousel_content === null
                      ? RichText.asText(title)
                      : RichText.asText(
                          carousel_content.body[0].primary.page_header
                        )}
                  </H1>
                  <P>
                    {carousel_content === null
                      ? RichText.asText(content)
                      : RichText.asText(
                          carousel_content.body[0].primary.intro__copy
                        )}
                  </P>
                  {carousel_content !== null && (
                    <LearnMore
                      to={toPath(
                        carousel_content._meta.type,
                        carousel_content._meta.uid
                      )}
                    >
                      Learn More
                    </LearnMore>
                  )}
                </Content>
              </HomeHeader>
              <BGOverlay />
            </Container>
          )
        )}
      </CarouselWrapper>
      <TextForm>
        <TextApp trackLocation="header" trackPage="home" light />
      </TextForm>
      <AppLinks>
        <AppStoreLinks />
      </AppLinks>
      {carousel.length > 1 && (
        <CarouselArrows>
          <PrevButton onClick={prevSlide}>→</PrevButton>
          <NextButton onClick={nextSlide}>→</NextButton>
        </CarouselArrows>
      )}
    </HeaderWrapper>
  );
};

export default Header;
