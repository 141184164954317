import React from "react";
import tw, { styled } from "twin.macro";
import { P, H4, arabicToRoman } from "../utils/helpers";

const HowItWorksWrapper = tw.section`
  relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-12 lg:max-w-screen-xl pt-8 lg:pt-0 md:flex md:flex-row md:items-start z-20
`;

const StepsWrapper = tw.div`
  relative lg:grid lg:grid-cols-3 gap-10 mt-8 text-center md:text-left z-10
`;

const StepsIntro = styled.div`
  ${tw`leading-6 font-medium lg:col-span-3`}
  & P {
    ${tw`mt-3 text-3xl leading-tight text-ink font-light`}
  }

  & H4 {
    ${tw`text-2xl leading-6 font-medium text-fire mt-12 lg:col-span-3`}
  }
`;

const AppImage = styled.img`
  ${tw`md:flex-none md:sticky md:top-8 md:-mr-16 lg:-mr-16 lg:p-0 relative z-0 pointer-events-none w-3/4 -mt-48 -mb-12 -ml-4 md:-ml-8`}
  @media (min-width: 768px) {
    width: 23rem;
    margin-top: -18rem;
    ${tw`pl-12`}
  }
  @media (min-width: 1024px) {
    margin-top: -16rem;
    width: 27rem;
  }
`;

const Step = tw.div`mb-12`;

const StepNumber = tw.p`text-fire font-serif text-2xl`;

const StepHeader = tw(P)`mt-4 font-medium text-lg`;

const StepBody = tw(P)`mt-4 text-base leading-6`;

const StepCallout = tw(P)`text-xs text-gray-500 leading-normal mt-4`;

const HowItWorks = ({ intro, steps }) => (
  <HowItWorksWrapper>
    <AppImage src="/assets/img/app-header.png" />
    <StepsWrapper>
      <StepsIntro>
        <P>{intro}</P>
        <H4>How it works</H4>
      </StepsIntro>
      {steps &&
        steps.map(
          ({ step_callout, step_header, step_number, step_text }, idx) => (
            <Step key={`step-${idx}`}>
              <StepNumber>{arabicToRoman(step_number) + "."}</StepNumber>
              <StepHeader>{step_header}</StepHeader>
              <StepBody>{step_text}</StepBody>
              {step_callout && <StepCallout>{step_callout}</StepCallout>}
            </Step>
          )
        )}
    </StepsWrapper>
  </HowItWorksWrapper>
);

export default HowItWorks;
